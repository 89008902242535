import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, signal } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faCheck,
  faCheckCircle,
  faChevronDown,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import {
  Observable,
  Subject,
  catchError,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { AppConfigService } from '../../../config.service';
import { environment } from '../../../environments/environment';
import { FancyboxCard } from '../../../models/components/fancyboxCard.model';
import { OrderSummary } from '../../../models/components/order/orderSummary.model';
import { OrderStatus } from '../../../models/order/enums/orderStatus.enum';
import { PaymentType } from '../../../models/order/enums/payment-type.enum';
import {
  CreateOrderItemRequest,
  CreateOrderRequest,
  OrderPaymentRequest,
} from '../../../models/order/order-contract.model';
import { Order } from '../../../models/order/order.model';
import { AuthService, User } from '../../../services/auth/auth.service';
import { LoaderService } from '../../../services/loader.service';
import { ModalService } from '../../../services/modal.service';
import { NotificationService } from '../../../services/notification.service';
import { OrderService } from '../../../services/order.service';
import { TextInputComponent } from '../../../shared/components/text-input/text-input.component';
import { RouteUrls } from '../../../shared/enums/route-urls.enum';
import { BasketItem } from '../../basket/models/basketItem.model';
import { BasketItemsService } from '../../basket/services/basketItems.service';
import {
  FANCYBOX_REF,
  FancyboxComponent,
} from '../../modals/fancybox/fancybox.component';
import { ProductType } from '../../video-generator-form/models/video-form-config';
import {
  VideoDeliveryTime,
  VideoQuality,
} from '../../video-generator-form/models/video-params.model';

declare var setAccount: any;
declare var getTokens: any;
declare var setIfieldStyle: any;
declare var enableAutoFormatting: any;
declare function enableGooglePay(): void;

interface OrderForm {
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  email: FormControl<string | null>;
  repeatEmail: FormControl<string | null>;
  street: FormControl<string | null>;
  postalCode: FormControl<string | null>;
  city: FormControl<string | null>;
}

interface CardForm {
  month: FormControl<string | null>;
  year: FormControl<string | null>;
}

@Component({
  standalone: true,
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    TextInputComponent,
  ],
})
export class OrderSummaryComponent implements OnInit {
  private _destroyed$ = new Subject<void>();
  readonly faCheckCircle = faCheckCircle;
  readonly faCheck = faCheck;
  readonly faChevronDown = faChevronDown;
  readonly productType = ProductType;
  readonly videoQuality = VideoQuality;
  readonly videoDeliveryTime = VideoDeliveryTime;
  readonly paypalImg = '/uploads/paypal_c5de5cd424.png';
  readonly creditCardsImg = '/uploads/cards_33ba032715.png';

  @Input() orderSummary: OrderSummary;
  orderRepayment: boolean = false;
  cardKnoxCdnUrl: SafeResourceUrl;
  cardKnoxGooglePayUrl: SafeResourceUrl;
  routeUrls = RouteUrls;
  iconLock = faLock;
  user$: Observable<User | null>;
  isSummaryOpen = signal(false);
  orderId: string | null;
  order$: Observable<Order>;

  googleScriptElement: HTMLScriptElement;
  googleToken: string;

  formGroup!: FormGroup<OrderForm>;
  cardFormGroup!: FormGroup<CardForm>;

  strapiUrl: string;
  basketItems: BasketItem[] = [];

  defaultStyle = {
    'border-width': '1px',
    'border-radius': '0.375rem',
    'padding-left': '0.5rem',
    'padding-right': '0.5rem',
    'padding-top': '0.75rem',
    'padding-bottom': '0.5rem',
    'font-size': '16px',
    width: '95%',
    outline: 'none',
    overflow: 'hidden',
    border: '1px solid rgb(229, 231, 235)',
  };

  constructor(
    private readonly modalService: ModalService,
    private readonly basketItemsService: BasketItemsService,
    private readonly loaderService: LoaderService,
    private readonly notificationService: NotificationService,
    private readonly orderService: OrderService,
    private readonly router: Router,
    public readonly sanitizer: DomSanitizer,
    private readonly configService: AppConfigService,
    private readonly authService: AuthService,
    private readonly formBuilder: FormBuilder,
    private route: ActivatedRoute,
  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
    this.orderId = this.route.snapshot.paramMap.get('id');
    this.orderRepayment = this.orderId ? true : false;
  }

  private readonly destroy$ = new Subject<void>();

  ngOnInit(): void {
    const config = this.configService.getConfigOrThrow();
    this.user$ = this.authService.user$;
    this.initItems();
    setAccount(
      config.cardKnoxPublicKey,
      environment.appName,
      environment.appVersion,
    );
    setIfieldStyle('card-number', this.defaultStyle);
    setIfieldStyle('cvv', this.defaultStyle);
    enableAutoFormatting();
    this.cardKnoxCdnUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      config.cardKnoxCdnUrl,
    );
    this.cardKnoxGooglePayUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      config.cardKnoxGooglePayUrl,
    );

    this.formGroup = this.formBuilder.group<OrderForm>(
      {
        firstName: new FormControl('', [
          Validators.required,
          Validators.maxLength(100),
        ]),
        lastName: new FormControl('', [
          Validators.required,
          Validators.maxLength(100),
        ]),
        email: new FormControl('', [
          Validators.required,
          Validators.email,
          Validators.maxLength(256),
        ]), //todo how to handle emails?
        repeatEmail: new FormControl('', [
          Validators.required,
          Validators.email,
          Validators.maxLength(256),
        ]),
        street: new FormControl('', [
          Validators.required,
          Validators.maxLength(100),
        ]),
        postalCode: new FormControl('', [
          Validators.required,
          Validators.maxLength(10),
        ]),
        city: new FormControl('', [
          Validators.required,
          Validators.maxLength(100),
        ]),
      },
      { validators: this.confirmEmailValidator },
    );

    this.cardFormGroup = this.formBuilder.group<CardForm>(
      {
        month: new FormControl('', [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(2),
        ]),
        year: new FormControl('', [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(2),
        ]),
      }
    );

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.formGroup.patchValue({
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        repeatEmail: user?.email,
        street: user?.street,
        postalCode: user?.postalCode,
        city: user?.city,
      });
    });

    if (this.orderId) {
      this.order$ = this.orderService.getOrder(this.orderId).pipe(
        takeUntil(this.destroy$),
        map((response) => {
          if (response.order.orderStatus == OrderStatus.InProgress) {
            this.router.navigate([this.routeUrls.Account]);
          }
          return response.order;
        })
      );
    }

    this.googleScriptElement = document.createElement("script");
    this.googleScriptElement.src = "src/assets/scripts/google-pay.js";
    this.googleScriptElement.type = "text/script;"
    document.body.appendChild(this.googleScriptElement);

    enableGooglePay();
  }

  initItems() {
    this.basketItems = this.basketItemsService.items;
  }

  get total() {
    return this.basketItemsService.total;
  }

  openFancybox(fancyboxCard: FancyboxCard) {
    if (!fancyboxCard.fancybox.data) {
      return;
    }

    const ref = this.modalService.openModal<FancyboxComponent>(
      FancyboxComponent,
      FANCYBOX_REF,
    );

    ref.setInput('fancybox', fancyboxCard.fancybox.data.attributes);
  }

  createOrder() {
    if (!this.formGroup.valid) {
      this.notificationService.showError('Please fill all required fields.');
      return;
    }

    this.loaderService.show();

    if (this.orderId) {
      this.payForOrder();
      return;
    }

    const orderItems: CreateOrderItemRequest[] = this.basketItems.map(
      (item) => {
        return {
          productSku: item.sku,
          jsonParameters: JSON.stringify(item.request),
          videoParams: item.videoParams,
          discountCodeId: item.discountCode?.id,
          blobFolderName: item.blobFolderName,
        };
      },
    );

    const request: CreateOrderRequest = {
      orderItems: orderItems,
      firstName: this.formGroup.controls.firstName.value ?? '',
      lastName: this.formGroup.controls.lastName.value ?? '',
      email: this.formGroup.controls.email.value ?? '',
      street: this.formGroup.controls.street.value ?? '',
      postalCode: this.formGroup.controls.postalCode.value ?? '',
      city: this.formGroup.controls.city.value ?? '',
    };

    this.sendOrderRequest(request);
  }

  sendOrderRequest(request: CreateOrderRequest) {
    this.orderService
      .createOrder(request)
      .pipe(
        takeUntil(this._destroyed$),
        tap((response) => {
          this.basketItemsService.clear();
          this.orderId = response.orderId;
          this.payForOrder();
        }),
        catchError((error) => {
          this.notificationService.showError(
            'An error occured while creating order. Try again.',
          );
          this.loaderService.hide();
          return of(null);
        }),
      )
      .subscribe();
  }

  payForOrder() {
    this.loaderService.show();

    if (this.googleToken) {
      this.googlePayment();
    }
    else {
      this.cardPayment();
    }
    this.loaderService.hide();
  }

  cardPayment() {
    getTokens(() => {
      const cardNumberTokenInput = document.querySelector(
        "[data-ifields-id='card-number-token']",
      ) as HTMLInputElement;
      const cvvTokenInput = document.querySelector(
        "[data-ifields-id='cvv-token']",
      ) as HTMLInputElement;

      const request: OrderPaymentRequest = {
        orderId: this.orderId!,
        cardNumberToken: cardNumberTokenInput?.value,
        cvvToken: cvvTokenInput?.value,
        month: this.cardFormGroup.controls.month.value
          ? this.cardFormGroup.controls.month.value
          : null,
        year: this.cardFormGroup.controls.year.value
          ? this.cardFormGroup.controls.year.value
          : null,
        firstName: this.formGroup.controls.firstName.value ?? '',
        lastName: this.formGroup.controls.lastName.value ?? '',
        email: this.formGroup.controls.email.value ?? '',
        street: this.formGroup.controls.street.value ?? '',
        postalCode: this.formGroup.controls.postalCode.value ?? '',
        city: this.formGroup.controls.city.value ?? '',
        paymentType: PaymentType.CreditCard,
      };

      this.sendPaymentRequest(request);
    });
  }

  googlePayment() {
    const request: OrderPaymentRequest = {
      orderId: this.orderId!,
      cardNumberToken: this.googleToken,
      cvvToken: "",
      month: this.cardFormGroup.controls.month.value
        ? this.cardFormGroup.controls.month.value
        : null,
      year: this.cardFormGroup.controls.year.value
        ? this.cardFormGroup.controls.year.value
        : null,
      firstName: this.formGroup.controls.firstName.value ?? '',
      lastName: this.formGroup.controls.lastName.value ?? '',
      email: this.formGroup.controls.email.value ?? '',
      street: this.formGroup.controls.street.value ?? '',
      postalCode: this.formGroup.controls.postalCode.value ?? '',
      city: this.formGroup.controls.city.value ?? '',
      paymentType: PaymentType.GooglePay,
    };
    this.sendPaymentRequest(request);

    this.googleToken = "";
  }

  sendPaymentRequest(request: OrderPaymentRequest) {
    this.orderService
      .payForOrder(request)
      .pipe(
        takeUntil(this._destroyed$),
        switchMap(() => this.authService.tryGetUser()),
        tap(() => {
          this.loaderService.hide();
          this.notificationService.showSuccess('Success.');
          this.router.navigate([this.routeUrls.Account]);
        }),
        catchError((error) => {
          this.notificationService.showError(
            'An error occured during payment. Try again.',
          );
          this.loaderService.hide();
          return of(null);
        }),
      )
      .subscribe();
  }

  confirmEmailValidator: ValidatorFn = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    return control.value.email === control.value.repeatEmail
      ? null
      : { EmailsNoMatch: true };
  };

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get isOrderFree() {
    return this.basketItems.every((x) => x.price == 0);
  }

  getVideoQualityLabel(quality: VideoQuality): string {
    switch (quality) {
      case VideoQuality._720p:
        return "HD";
      case VideoQuality._1080p:
        return "Full HD";
      case VideoQuality._4K:
        return "4K";
      default:
        return "Unknown Quality";
    }
  }

  returnToBasket() {
    this.router.navigate([this.routeUrls.Basket]);
  }
}
