import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, race, Subject, takeUntil, tap } from 'rxjs';
import { environment } from '../app/environments/environment';

export interface AppConfig {
  strapiUrl: string;
  graphApiUrl: string,
  authToken: string,
  cardKnoxCdnUrl: string,
  cardKnoxGooglePayUrl: string,
  cardKnoxPublicKey: string,
  audioStorageUrl: string,
  audioGeneratorMaxAttemptsWithoutDelay: number,
  audioGeneratorDelayInMs: number,
}

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private _destroyed$ = new Subject<void>();
  private readonly apiUrl = `${environment.apiUrl}/ClientConfig`;

  public config$?: BehaviorSubject<AppConfig>;

  constructor(private http: HttpClient) {
    http.get<AppConfig>(this.apiUrl)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((config) => {
        this.config$ = new BehaviorSubject<AppConfig>(config);
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  getConfigOrThrow() {
    if (!this.config$) {
      throw new Error('Config not loaded');
    }
    return this.config$.value;
  }

  getConfig() {
    return race(
      this.config$?.asObservable() ??
      this.http.get<AppConfig>(this.apiUrl).pipe(
        takeUntil(this._destroyed$),
        tap((config) => {
          this.config$ = new BehaviorSubject<AppConfig>(config);
        })
      ));
  }
}
